import './index.scss'
import loaded from './image/loaded.png'
export default {
  name: "Form",
  props: {
    renderOption: {type: Array, required: true},
    props: {type: Object},
    model: {type: Object},
    rules: {type: Object},
    splice: {type: Array}
  },
  data() {
    return {
      number: {},
      dialogImageUrl: '',
      isShowDialogImg: false,
    }
  },
  render() {
    return (
      <el-form
        props={{model: this.model, rules: this.rules, validateOnRuleChange: false, ...(this.props), ...this.$attrs}}
        on={{...this.$listeners}}
        ref={"form"}
        validateOnRuleChange={false}
        class={'jsx_form_wrapper'}
      >
        { this.handleSplice(this.splice) }
        <div>{this.renderDialogImg()}</div>
      </el-form>
    )
  },
  watch: {
    model: {
      immediate: true,
      handler: function () {
        let that = this
        let numberList = that.renderOption.filter((item) => item.type === 'number')
        numberList.forEach((item) => {
          // 设定number 的代替
          if(!isNaN(Number(that.model[item.prop])) && that.model[item.prop] !== '' && that.model[item.prop] !== null){
            that.$set(this.number, item.prop, Number(that.model[item.prop]).toFixed(item.precision || 0));
          } else {
            that.$set(this.number, item.prop, that.model[item.prop]);
          }
        })
      }
    },
    renderOption: {
      immediate: true,
      handler: function () {
        let that = this
        let numberList = that.renderOption.filter((item) => item.type === 'number')
        numberList.forEach((item) => {
          // 设定number 的代替
          if(!isNaN(Number(that.model[item.prop])) && that.model[item.prop] !== '' && that.model[item.prop] !== null){
            that.$set(this.number, item.prop, Number(that.model[item.prop]).toFixed(item.precision || 0));
          } else {
            that.$set(this.number, item.prop, that.model[item.prop]);
          }
        })
      }
    }
  },
  methods: {
    // 切割不同列
    handleSplice(splice) {
      if(splice && splice.length > 0) {
        let AllOption = [...this.renderOption]
        let option = []
        for(let i = 0; i < splice.length + 1; i++) {
          if(splice[i] && AllOption.length > 0){
            option.push(AllOption?.splice(0, splice[i]))
          } else {
            option.push(AllOption)
          }
        }
        return (
         <div class={"form_splice_wrapper"}>
           {
             option?.map((column) => {
               return  (<div class={"form_column_wrapper"}
                             style={{
                               paddingRight: this.props.paddingRight,
                               paddingLeft: this.props.paddingLeft
                             }}>
                 { this.handleRenderList(column) }
               </div>)
             })
           }
         </div>
        )
      } else {
        return (
          <div class={"form_column_wrapper"}>
            { this.renderOption && this.handleRenderList(this.renderOption) }
          </div>
        )
      }
    },

    // 判断type并渲染对应
    handleRenderList(renderList) {
      let itemElement = []
      const typeToRender = (type, item) => {
        switch (type) {
          case 'input': itemElement.push(this.renderInput(item))
            break
          case 'number': itemElement.push(this.renderNumber(item))
            break
          case 'text': itemElement.push(this.renderText(item))
            break
          case 'date': itemElement.push(this.renderDate(item))
            break
          case 'button': itemElement.push(this.renderButton(item))
            break
          case 'slot': itemElement.push(this.renderSlot(item))
            break
          case 'select': itemElement.push(this.renderSelect(item))
            break
          case 'radio': itemElement.push(this.renderRadio(item))
            break
          case 'image': itemElement.push(this.renderImage(item))
            break
          case 'area': itemElement.push(this.renderArea(item))
            break
          case 'checkbox': itemElement.push(this.renderCheckbox(item))
            break
          default : itemElement.push(this.renderType(item, type))
        }
      }
      renderList.forEach(({type,show = true, ...item}) => {
        if(show){
          typeToRender(type, item)
        }
      })
      return itemElement
    },

    // 渲染输入框
    renderInput({label, prop,  props, placeholder, on, clearable=true,...Attribute}) {
      if(props){
        return (
            <el-form-item label={ label } prop={ prop } props={{...Attribute}} {...Attribute}>
              <el-input v-model={this.model[prop]} on={{...on}} clearable={clearable}
                        title={this.model[prop]}  mouseover={this.model[prop]} maxlength={props.maxlength} showWordLimit={props.showWordLimit}
                        placeholder={placeholder} size={'small'} props={{...props}} {...props}/>
              { Attribute.sign && <span class={'inputSpan'}>{ Attribute.sign }</span>}
            </el-form-item>
        )
      }
      return (
          <el-form-item label={ label } prop={ prop } props={{...Attribute}} {...Attribute}>
            <el-input v-model={this.model[prop]} on={{...on}} clearable={clearable}
                      title={this.model[prop]}  mouseover={this.model[prop]}
                      placeholder={placeholder} size={'small'} props={{...props}} {...props}/>
            { Attribute.sign && <span class={'inputSpan'}>{ Attribute.sign }</span>}
          </el-form-item>
      )
    },

    // 渲染不同类型输入框
    renderType({label, prop, props: {maxlength, showWordLimit, ...props},on, placeholder, ...Attribute}, type) {
      return (
        <el-form-item label={ label } prop={ prop } props={{...Attribute}} {...Attribute}>
          <el-input v-model={this.model[prop]} type={type}
                    placeholder={placeholder} size={'small'} on={{...on}}
                    maxlength={maxlength} showWordLimit={showWordLimit}
                    props={{...props}} {...props}/>
          { Attribute.sign && <span class={'inputSpan'}>{ Attribute.sign }</span>}
        </el-form-item>
      )
    },

    // 渲染数字
    renderNumber({label, prop, props, on, placeholder, precision = 0,clearable=true, ...Attribute}) {
      let that = this
      const handleChange = (newValue) => {
        if(!isNaN(Number(newValue)) && newValue !== '' && newValue !== null) {
           that.model[prop] = Number(newValue)
        } else if(newValue === '' || newValue === null) {
          that.model[prop] = newValue
        }
         if(!isNaN(Number(newValue)) || newValue.charAt(newValue.length-1) === '.') {
           that.number[prop] = newValue
         }
      }
      const handleBlur = () => {
        let num = Number(that.model[prop]).toFixed(precision)
        if(!isNaN(Number(num)) && that.model[prop] !== '' && that.model[prop] !== null){
          that.number[prop] = num
          that.model[prop] = Number(num)
        }
      }
      return (
        <el-form-item label={ label } prop={ prop } props={{...Attribute}} {...Attribute} class={['number_form_wrapper']}>
          <el-input value={that.number[prop] } placeholder={placeholder}
                    onInput={ handleChange } onBlur={ handleBlur }
                    on={{...on}} clearable={clearable}
                    size={'small'} props={{...props}} {...props}/>
          { Attribute.sign && <span class={'inputSpan'}>{ Attribute.sign }</span>}
        </el-form-item>
      )
    },

    // 渲染日期选择
    renderDate({label, prop, props, on, placeholder, clearable=true,
                 format="yyyy-MM-dd HH:mm:ss", ...Attribute}) {
      return (
        <el-form-item label={ label } prop={ prop } props={{...Attribute}} on={{...on}} {...Attribute}>
          <el-date-picker v-model={this.model[prop]} placeholder={placeholder}
                          value-format={format} clearable={clearable}
                          size={'small'} props={{...props}} {...props}/>
          { Attribute.sign && <span class={'inputSpan'}>{ Attribute.sign }</span>}
        </el-form-item>
      )
    },

    // 渲染插槽
    renderSlot({label, prop, slotName, ...Attribute}) {
      return (
        <el-form-item label={ label } prop={ prop } {...Attribute} props={{...Attribute}}>
          {this.$slots[slotName]}
        </el-form-item>
      )
    },

    // 渲染查看
    renderText({label, prop, no = '暂无', yes, v = '0', p, ...Attribute}) {
      return (
        <el-form-item label={ label } { ...Attribute } class={['text_form_wrapper']} props={{...Attribute}}>
          {
            yes ? (this.model[prop] == v ? no : yes) :
              p ? (Number(this.model[prop] || 0).toFixed(p)) :
              (this.model[prop] || no)
          }
          { (this.model[prop] || this.model[prop] == 0)&&
          Attribute.sign && <span class={'inputSign'}>{ Attribute.sign }</span>}
        </el-form-item>
      )
    },

    // 渲染图片(单张)
    renderImage({label, src, fit = 'contain', height, props, ...Attribute}) {
      const scopedSlots = {
        placeholder: () => {
          return (
              <el-image
                  src={loaded}
                  props={{...props}}
                  {...props}
                  fit={fit}
                  style={{height: height || '80px'}} />
          )
        },
        error: () => {
          return (
              <el-image
                  src={loaded}
                  props={{ ...props }}
                  { ...props }
                  fit={ fit }
                  style={{height: height || '80px'}} />
          )
        }
      }
      return (<el-form-item label={ label } {...Attribute} props={{...Attribute}}>
        {
          (Object.prototype.toString.call(src) === '[object Array]' && src.length>0 ) ?
              src.map((item, index) => {
                return (<el-image
                    key={index}
                    style={{width: '78px', height: height || '80px', margin: '0 6px 0 0'}}
                    src={item}
                    props={{...props}}
                    {...props}
                    {...{scopedSlots}}
                    onClick={() => this.handleShowDialogImg(src[index]) }
                    fit={fit}/>)
              }) : '暂无'
        }
        {/*
        <el-image
            src={src}
            props={{...props}}
            {...props}
            {...{scopedSlots}}
            style={{height: height || '80px'}} />
        */}
      </el-form-item>)
    },

    // 渲染区域列表
    renderArea({label, prop, ids=[], option, multiple=true, placeholder,
                 width='100%', backSave, clearable=true,  ...Attribute}) {
      const handleBackSave = (value) => {
        let showValue = null
        let ids = []
        if (multiple === false) {
          // 单选 拼接回显
          showValue = `${value?.areaName?value?.areaName+'-':''}${value.building||''}-${value.roomNumber||''} ${value.userName||''}`
          ids = [value.housingId]
        } else if (multiple === true) {
          // 多选 拼接回显
          let valueNameList = []
          value.forEach((item) => {
            let itemValue
            itemValue = `${value?.areaName?value?.areaName+'-':''}${item.building||''}-${item.roomNumber||''} ${item.userName||''}`
            valueNameList.push(itemValue);
            ids.push(value.housingId)
          });
          showValue = valueNameList.join(",");
        }
        backSave(value, showValue, ids)
        // if(value?.length > 999) {
        //   this.$message.error("选择房屋过多，请重新选择")
        // }
      }
      // 打开区域模态框
      const changHouse = () => {
        this.$refs?.house?.show(ids);
      }
      // 清除数据
      const handleClear = () => {
        backSave(null , null, [])
      }
      return (
        <el-form-item label={ label } prop={ prop } {...Attribute} props={{...Attribute}}>
          <div class={'area_wrapper'}>
            <ch-select-house-list
              ref={'house'}
              houseList={option}
              multiple={multiple}
              on-on-back={handleBackSave}
            />
            <el-input
              size="small"
              style={{width: width}}
              v-model={this.model[prop]}
              readonly
              placeholder={placeholder}
              on={{focus: changHouse}}
            ><ch-icon name={"down"} class={"down_img"} slot={"suffix"} onClick={changHouse}/>
            </el-input>
            { clearable && <ch-button class={"clear-button"} type={'link'} onClick={handleClear}>清除</ch-button>}
          </div>
        </el-form-item>
      )
    },

    // 渲染button
    renderButton({button, ...Attribute}) {
      return (
        <div {...Attribute}>
          {
            button?.map( (item) => {
              let { text, onClick, props, ...Attribute } = item
              return ( <el-button
                key={text}
                props={{...props}}
                onClick={() => onClick() }
                { ...Attribute }>
                {text}
              </el-button>)
            })
          }
        </div>
      )
    },

    // 渲染选择框
    renderSelect({ label, prop, name, on, value = 'value', props, placeholder, option,clearable=true, ...Attribute }) {
      return (
        <el-form-item label={ label } prop={ prop } {...Attribute} props={{...Attribute}}>
          <el-select
            class={'input_width'} size={'small'} clearable={clearable} placeholder={placeholder}
            on={{...on}} v-model={ this.model[prop] }{...props} props={{ ...props }} >
            {
              option?.map(( item ) => (
                <el-option
                  key={ item[value] }
                  label={ item[name] }
                  value={ item[value] }
                />
              ))
            }
          </el-select>
        </el-form-item>
      )
    },

    // 渲染单选框
    renderRadio({label, prop, option, props, on, ...Attribute}) {
      return (
        <el-form-item label={ label } prop={ prop } {...Attribute} props={{...Attribute}}>
          <el-radio-group v-model={this.model[prop]} on={{...on}} props={{...props}} {...props}>
            {
              option?.map(({value, label}) => {
                return <el-radio label={value} key={label}>{label}</el-radio>
              })
            }
          </el-radio-group>
        </el-form-item>
      )
    },

    // 渲染多选框
    renderCheckbox({label, prop, option, props, on, ...Attribute}) {
      return (
        <el-form-item label={ label } prop={ prop } {...Attribute} props={{...Attribute}} style={{marginBottom: '0px'}}>
          <el-checkbox-group v-model={this.model[prop]} on={{...on}} props={{...props}} {...props}>
            {
              option?.map(({label, text}) => {
                return <el-checkbox label={label} key={label}>{text}</el-checkbox>
              })
            }
          </el-checkbox-group>
        </el-form-item>
      )
    },
    
    // 验证
    validate(callBack) {
      this.$refs.form.validate((valid) => {
        if (valid) {
          callBack(true)
        } else {
          callBack(false)
          setTimeout(() => {
            this.$message({
              type: "error",
              message: "必填项未填写完整或者有误！请重新填写",
            })
          },300)
          return false;
        }
      });
    },

    // 重置Form表单数据
    resetFields(){
      this.$nextTick(() => {
        this.$refs.form?.resetFields()
      })
    },

    // 重置Form表单验证
    clearValidate(name=""){
      this.$nextTick(() => {
        if(!name){
          this.$refs.form?.clearValidate()
        }else{
          this.$refs.form?.clearValidate(name)
        }
      })
    },

    // 重置Foem
    resetForm(){
      this.$nextTick(() => {
        this.$refs.form?.resetFields()
        this.$refs.form?.clearValidate()
      })
    },

    //获取ref
    getRef(){
      return this.$refs.form;
    },

    renderDialogImg() {
      let that = this
      // 关键代码 .sync
      const listeners = {
        'update:visible': value => {
          that.isShowDialogImg = value
        }
      }
      // 弹出预览图片
      return (<el-dialog
          width={"30%"}
          class={'img-dialog-wrapper'}
          visible={ that.isShowDialogImg } {...{ on: listeners }}
          appendToBody={ true }>
        <img src={ that.dialogImageUrl } alt={'未加载'} />
      </el-dialog>)
    },
    // 执行hover 图片 + 号 预览图片
    handleShowDialogImg(url){
      this.dialogImageUrl = url;
      this.isShowDialogImg = true;
    },

  }
}